import React from "react"

const ButtonSVG = ({ fill = null }) => {
  return (
    // <!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg
      version="1.1"
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 110 100"
      className=" h-auto"
    >
      <g>
        <g>
          <g>
            <polygon
              style={{ fill: "white" }}
              points="36.2,0 0,0 72.9,50 0,100 38,100 110,50.6 			"
            />
          </g>
        </g>
        <g>
          <g>
            <polygon
              style={{ fill: `${fill || "rgb(94,158,171)"}` }}
              points="0,0 0,100 72.9,50 			"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ButtonSVG
