/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../styles/components/_layout.scss"

import NavBar from "./navbar/navbar"
import Footer from "./footer"

import ButtonTemplate from "./buttons/ButtonTemplate"

const Layout = ({ children, hideAllDepartmentsButton = false }) => {
  return (
    <div className="min-h-screen flex flex-col items-center px-2 md:px-10 lg:px-20 pt-2 md:pt-12 lg:pt-20 bg-gradient-to-b  from-shibBlue to-white ">
      <div className="  rounded-t-2xl  relative overflow-hidden max-w-7xl w-full">
        {/* Header is given class because index uses custom a header */}

        <NavBar />
        {/* <div className="layout__main__wrapper"> */}
        <main className="layout__main grid flex-grow bg-white ">
          {children}
          {hideAllDepartmentsButton ? null : (
            <ButtonTemplate
              to="/departments"
              text="All Departments"
              className="text-white justify-self-center my-10 uppercase tracking-wide text-xl"
            />
          )}
        </main>
        <Footer />
        {/* </div> */}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
