import { graphql, Link } from "gatsby"
import GatsbyImage from "gatsby-image"
import React from "react"

{
  /* Logo Layer of navbar */
  // Note: Wapekeka's logo needed custom sizing bc of it's shape, at the request of KR - 2021-09-01
}

const CommunityLogoLink = ({ communities }) => {
  return (
    <div className="bg-white flex   justify-between gap-x-1 sm:gap-x-2 py-2 rounded-tr-2xl px-1 sm:px-8 lg:px-10 ">
      {communities.map((community, index) => (
        <Link
          to={`/communities/${community.handle}`}
          className="flex justify-center items-center transform hover:scale-110 duration-150 ease-in-out relative "
          key={`${index}`}
          title={`${community.name} First Nation`}
        >
          <GatsbyImage
            fluid={community.logoFluid}
            className={`  ${
              community.name === "Wapekeka"
                ? " w-20   sm:w-24 md:w-32"
                : "w-16  sm:w-20 md:w-24"
            }`}
          />
        </Link>
      ))}
    </div>
  )
}

export const query = graphql`
  fragment CommunityLogo on File {
    id
    name
    childImageSharp {
      fluid(maxHeight: 200) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`

export default CommunityLogoLink
