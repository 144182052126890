import { Link } from "gatsby"
import React from "react"
import ButtonSVG from "../svg/ButtonSVG"

const ButtonTemplate = ({
  text = "All Departments",
  bannerBg = null,
  triangleFill = null,
  to = "/",
  className = "",
  index = 0,
}) => {
  const isExternal = to && to.split("/")[0] !== ""

  const buttonColors = [
    {
      color: "blue",
      bannerBg: "rgb(107,138,167)",
      triangleFill: "rgb(75,103,129)",
    },
    {
      color: "red",
      bannerBg: "rgb(197,113,109)",
      triangleFill: "rgb(186,63,76)",
    },
    {
      color: "yellow",
      bannerBg: "rgb(225,184,77)",
      triangleFill: "rgb(195,153,61)",
    },
  ]
  const shortenedText = text.slice(0, 25)
  // If index isn't provided then defaul to 0
  const colorIndex = index % 3 || 0
  const bannerBgDefault = buttonColors[colorIndex].bannerBg
  const triangleFillDefault = buttonColors[colorIndex].triangleFill
  return (
    <div
      className={`flex flex-col overflow-hidden whitespace-nowrap transform hover:scale-110 duration-300 ease-in-out shadow ${className}`}
    >
      {isExternal ? (
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          title={text}
          className={` flex relative       `}
          style={{
            backgroundColor: `${bannerBg || bannerBgDefault}`,
          }}
        >
          <div className="relative w-10 md:w-14  ">
            <ButtonSVG fill={triangleFill || triangleFillDefault} />
          </div>
          <div className=" grid items-center flex-grow px-3  md:text-2xl  font-bold pt-1 leading-none ">
            <span className="hidden md:inline-block">{text}</span>
            <span className="md:hidden">
              <span className="">{shortenedText}</span>
              {text.length >= 25 ? <span>...</span> : null}
            </span>
          </div>
        </a>
      ) : (
        <Link
          to={to}
          className={` flex relative       `}
          style={{
            backgroundColor: `${bannerBg || bannerBgDefault}`,
          }}
          title={text}
        >
          <div className="relative w-10 md:w-14  ">
            <ButtonSVG fill={triangleFill || triangleFillDefault} />
          </div>
          <div className=" grid items-center flex-grow px-3  md:text-2xl  font-bold pt-1 leading-none ">
            <span className="hidden md:inline-block">{text}</span>
            <span className="md:hidden">
              <span className="">{shortenedText}</span>
              {text.length >= 25 ? <span>...</span> : null}
            </span>
          </div>
        </Link>
      )}
    </div>
  )
}

export default ButtonTemplate
