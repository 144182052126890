import { Link } from "gatsby"
import React from "react"

// Resource on dropdown menus:
// https://www.w3schools.com/css/css_dropdowns.asp

const DropDownLink = ({ children, handle, dropdownWrap = false }) => {
  const wrapClass = dropdownWrap ? "" : "whitespace-nowrap"
  return (
    // whitespace nowrap prevents text-wrapping

    <Link
      className={`hover:bg-blue-light hover:text-white text-black text-2xl p-3 border-t first:border-t-0  border-gray-dark first:border-b-0 bg-white ${wrapClass} `}
      to={handle}
      activeClassName="text-white bg-blue-light"
    >
      {children}
    </Link>
  )
}

const DropDownExternalLink = ({ url = "#", name, dropdownWrap = false }) => {
  const wrapClass = dropdownWrap ? "" : "whitespace-no-wrap"
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={`hover:bg-blue-light hover:text-white text-black text-lg p-3 border-t first:border-t-0  border-gray-dark first:border-b-0 bg-white ${wrapClass} `}
    >
      {name}
    </a>
  )
}

const MobileDropdownMenu = ({
  dropdown,
  dropdownWrap = false,
  dropDownVisible = null,
}) => {
  const wrapClass = dropdownWrap ? "right-0" : ""
  return (
    // dropdown wrap sits to the right if dropdownWrap is enabled for nav items near the edge of the screen
    <div
      className={`${
        dropDownVisible ? "grid" : "hidden"
      } nav__dropdownContent absolute  z-20 ml-1 border border-gray-dark ${wrapClass} shadow `}
      style={{ minWidth: 200 }}
    >
      {dropdown.map((item, index) => {
        {
          /* render as internal link if has handle */
        }
        if (item.handle) {
          return (
            <DropDownLink
              handle={item.handle}
              key={`dropdown-${item.name}-${index}`}
              dropdownWrap={dropdownWrap}
            >
              {item.name}
            </DropDownLink>
          )
        }
        if (item.url) {
          return (
            <DropDownExternalLink
              name={item.name}
              key={`dropdown-${item.name}-${index}`}
              url={item.url}
            />
          )
        }
      })}
    </div>
  )
}

export default MobileDropdownMenu
