import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { FaEnvelope, FaRegBookmark, FaMicrosoft } from "react-icons/fa"
import { MdSettings } from "react-icons/md"
import Img from "gatsby-image"

const StaffLink = ({ children, url = "/#", text, internal = false }) => {
  const styling =
    "flex items-center hover:text-blue-dark justify-center lg:justify-start"
  const element = internal ? (
    <Link to={url} className={styling}>
      {children}
      <span className="pl-4">{text}</span>
    </Link>
  ) : (
    <a href={url} target="_blank" rel="noopener noreferrer" className={styling}>
      {/* children is for icon component to be added */}
      {children}
      <span className="pl-4">{text}</span>
    </a>
  )

  return element
}

const StaffTitle = ({ children }) => {
  return <div className="font-bold text-2xl pb-2 mt-4 lg:mt-0">{children}</div>
}

export default () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      sanityLogo: file(relativePath: { eq: "sanity-logo.png" }) {
        id
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <footer className="">
      <div className="bg-blue-light py-8">
        <div className="flex-column lg:flex justify-center">
          <div className="text-center lg:text-left md:ml-4 mb-4">
            <div className="font-bold text-2xl pb-2">Resources</div>
            <div className="flex justify-center text-left">
              <div className="flex flex-col mr-4">
                <Link className="hover:text-blue-dark" to="/">
                  Home
                </Link>
                <Link className="hover:text-blue-dark" to="/about">
                  About Us
                </Link>
                <Link className="hover:text-blue-dark" to="/contact">
                  Contact Us
                </Link>
                <Link className="hover:text-blue-dark" to="/jobs">
                  Jobs
                </Link>
                <Link className="hover:text-blue-dark" to="/news">
                  News
                </Link>
              </div>
              <div className="flex flex-col pl-4 ml-4">
                <Link className="hover:text-blue-dark" to="/departments">
                  Departments
                </Link>
                <Link className="hover:text-blue-dark" to="/communities">
                  Communities
                </Link>
                <Link className="hover:text-blue-dark" to="/calendar">
                  Calendar
                </Link>
                {/* <Link className="hover:text-blue-dark" to="/medias">
                  Media
                </Link> */}
              </div>
            </div>
          </div>
          <div className="sm:mx-40">
            <div className="font-bold text-2xl pb-2 text-center lg:text-left">
              Contact
            </div>
            <div className="text-center lg:text-left">
              <div className="mb-4">
                Shibogama First Nations Council
                <br />
                81 King St., P.O. Box 449
                <br />
                Sioux Lookout, ON
                <br />
                P8T 1A5
              </div>{" "}
              <div>
                <span className=""></span>
                <a href="tel:807-737-2662" className="hover:text-blue-dark">
                  Phone: 807-737-2662
                </a>
                <br />
                <span>Fax: 807-737-1583</span>
              </div>
            </div>
          </div>
          <div className="text-center lg:text-left justify-center">
            <div className="font-bold text-2xl pb-2 mt-4 lg:mt-0">Staff</div>
            <StaffLink
              url="https://www.microsoft.com/en-us/microsoft-365/outlook/log-in"
              text="Outlook"
            >
              <FaMicrosoft />
            </StaffLink>

            <StaffLink url="https://shibogama.sanity.studio/" text="Admin">
              <MdSettings />
            </StaffLink>
            {/* <StaffLink url="/roombooking" text="Room Booking" internal={true}>
              <FaRegBookmark />
            </StaffLink> */}
            {/* Sanity.io Logo and Link */}

            <a
              href="https://www.sanity.io"
              target="_blank"
              rel="noopener noreferrer"
              className="grid justify-items-center lg:justify-items-start gap-y-2 mt-8 md:mt-5"
            >
              <div className="font-bold">Structured content powered by:</div>
              <Img fixed={data.sanityLogo.childImageSharp.fixed} className="" />
            </a>
          </div>
        </div>
      </div>

      <div className="bg-blue-dark p-4">
        <div className="grid mx-auto justify-center">
          <div className="text-center">
            © {new Date().getFullYear()}, Shibogama First Nations Council. All
            Rights Reserved
          </div>
          {/* <span className="text-blue-light">&nbsp;|&nbsp;</span> */}
          {/* <a
            href="#"
            className="hover:font-bold"
            target="__blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a> */}
        </div>
        <div className="grid grid-flow-col justify-center mt-2">
          Website by&nbsp;
          <a
            className="underline"
            href="https://www.borealisweb.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            Borealis Web Development
          </a>
        </div>
      </div>
    </footer>
  )
}
